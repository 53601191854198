@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';

.textarea {
  font-size: map-get($writeMode, input, font-size) !important;
  font-weight: map-get($writeMode, input, font-weight) !important;
  font-style: map-get($writeMode, input, font-style) !important;
  // box-shadow: $inputShadow;

  &--width {
    @each $name, $item in map-get($entry, width) {
      &--#{$name} {
        width: calc(#{$item} * 2);
      }
    }

    &--initial {
      width: initial !important;
    }

    &--full {
      min-width: 100% !important;
    }
  }

  &--height {
    @each $name, $item in map-get($entry, height) {
      &--#{$name} {
        height: calc(#{$item} * 4) !important;
      }
    }
    &--initial {
      height: initial !important;
    }

    &--full {
      height: 100% !important;
    }
  }

  &--padding {
    @each $name, $item in map-get($entry, padding) {
      &--#{$name} {
        padding-left: map-get($entry, padding, x) !important;
        padding-right: map-get($entry, padding, x) !important;
        padding-top: map-get($entry, padding, x) !important;
        padding-bottom: map-get($entry, padding, x) !important;
      }
    }
  }
}
