@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/placeholder.scss';

.statistic {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;

  &__contain {
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-statistic-content {
      width: 100% !important;
      margin: 15px 0px;
      display: flex;
      justify-content: center;
      align-items: center;

      .ant-statistic-content-value {
        font-size: size(26px);
      }

      .ant-statistic-content-prefix,
      .ant-statistic-content-suffix {
        font-size: size(23px);
      }

      .ant-statistic-content-suffix {
        margin-left: size(10px);
      }
    }
  }

  &__explain {
    font-size: size(14px);
    font-weight: 500;
    color: var(--color-label);
  }
}
