@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/mixin.scss';

.page {
  @media screen and (max-width: map-get($device, desktop)) {
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: map-get($device, desktop)) {
    display: grid;
    grid-template: 1fr / 1fr;
    height: 100%;
    overflow: auto;
    @include scrollbar();
  }

  &__contain {
    position: relative;
    @include scrollbar();

    @media screen and (max-width: map-get($device, desktop)) {
      display: flex;
      flex-direction: column;
      padding: size(20px) 0px;
      height: 100%;
    }

    @media screen and (min-width: map-get($device, desktop)) {
      overflow: auto;
      // display: flow-root;
      padding: size(15px) size(15px) size(15px) size(20px);
    }

    // &:has(.navinline) {
    // ! temporaire
    // grid-template-rows: min-content;
    // }
  }
}
