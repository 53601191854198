@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/mixin.scss';

.main {
  width: 100%;

  @media screen and (min-width: map-get($device, desktop)) {
    display: grid;
    grid-template-rows: min-content 1fr;
    grid-template-columns: 1fr;
    overflow: auto;
    height: 100%;
  }

  @media screen and (max-width: map-get($device, desktop)) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__mask {
    height: 100%;
    width: 100%;
    position: absolute;
    inset: 0 0 0 0;
    z-index: 10000;
    background-color: var(--color-yin);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__header {
    overflow: visible;
  }

  &__contain {
    overflow: hidden;

    &__items {
      height: 100%;

      @media screen and (max-width: map-get($breakpoint, 'navigation')) {
        display: grid;
        grid-template-rows: min-content min-content 1fr;
        grid-template-columns: 1fr;
      }

      @media screen and (min-width: map-get($breakpoint, 'navigation')) {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: min-content min-content 1fr;
      }

      @media screen and (max-width: map-get($device, 'desktop')) {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      &__elevate {
        // z-index: 3;
        // position: absolute;
        // inset: 0 0 0 0;
      }

      &__nav {
        z-index: 2;
        overflow: hidden;
      }

      &__page {
        overflow: hidden;
        z-index: 1;
      }
    }
  }
}
