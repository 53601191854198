@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';

.ant-select-item-option-selected {
  * {
    //! Je devrais trouver un méthode pour transformer les variables CSS en vrai variable SASS
    // color: get-contrast-color(var(--color-primary)) !important;
  }
}

// .ant-select-item-option-content,
// .ant-select-selection-item {
//   overflow: clip !important;
//   overflow-clip-margin: 5px !important;
// }

.select,
.ant-select-dropdown {
  * {
    font-size: map-get($writeMode, input, font-size) !important;
    font-weight: map-get($writeMode, input, font-weight) !important;
    font-style: map-get($writeMode, input, font-style) !important;
  }
}

.select {
  * {
    color: inherit !important;
  }

  &--backgroundColor {
    @each $name, $item in map-get($entry, background-color) {
      &--#{$name} {
        .ant-select-arrow,
        .ant-select-selector {
          color: map-get($item, color) !important;
        }
        .ant-select-selector {
          background-color: map-get($item, background-color) !important;
        }
      }
    }
  }

  &--width {
    @each $name, $item in map-get($entry, width) {
      &--#{$name} {
        width: #{$item} !important;
      }
    }
  }

  &--minWidth {
    @each $name, $item in map-get($entry, width) {
      &--#{$name} {
        min-width: #{$item};
      }
    }
  }

  &--height {
    @each $name, $item in map-get($entry, height) {
      &--#{$name} {
        height: #{$item} !important;

        .ant-select-selector {
          height: #{$item} !important;
        }

        .ant-select-arrow,
        .ant-select-clear {
          margin-top: 0px !important;
        }
      }
    }
  }

  .ant-select-selector {
    padding: map-get($entry, padding, y) map-get($entry, padding, x) !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    overflow: hidden;
    // box-shadow: $inputShadow;

    .ant-select-selection-item {
      width: calc(100% - 5px) !important;
      // overflow: hidden;
      text-overflow: ellipsis;
      // height: 100%;
      // display: flex;
      // justify-content: flex-start;
      // align-items: center;
      // line-height: initial !important;
      // padding-inline-end: 25px !important;
    }
  }

  .ant-select-arrow,
  .ant-select-clear {
    margin: 0px !important;
    transform: translateY(-50%) !important;
    vertical-align: 0px !important;
    position: absolute !important;
    margin-top: 0px !important;
  }
}

.ant-select-multiple {
  .ant-select-selector {
    height: 100% !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;

    .ant-select-selection-item {
      padding-inline-end: 5px !important;
      margin: 0px 5px 0px 0px !important;
      flex: 1 !important;

      .ant-select-selection-item-content {
        padding: size(3px) 0px !important;
        margin-inline-end: 0px !important;
        margin-right: 5px !important;
        font-size: size(12px) !important;
      }
    }
  }
}

.ant-select-dropdown {
  .ant-select-item-option-selected {
    background-color: var(--color-primary) !important;

    * {
      color: var(--color-primary-over) !important;
    }
  }

  .ant-select-item {
    min-height: size(32px) !important;
    padding: size(9px) size(12px);
  }
}
