@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/mixin.scss';

$colHeight: (
  initial: min-content,
  full: 100%,
);

$colWidth: (
  initial: initial,
  full: 100%,
);

.col {
  display: flex;
  flex-direction: column;
  position: relative;

  &--height {
    @each $name, $item in $colHeight {
      &--#{$name} {
        height: $item;
      }
    }
  }

  &--width {
    @each $name, $item in $colWidth {
      &--#{$name} {
        width: $item;
      }
    }
  }

  &--scrollY {
    overflow-y: auto;
    overflow-x: hidden;
    @include scrollbar();
  }
}
