@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';

.avatar {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: size(30px);
  aspect-ratio: 1;
  // height: 100%;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  object-fit: cover;
  overflow: hidden;

  &__image {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
  }
}
