@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/mixin.scss';

.elevate {
  z-index: 99;
  position: fixed;
  inset: 0 0 0 0;
  height: 100%;
  display: grid;
  grid-template: 1fr / 1fr;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
  background-color: var(--color-elevate);
  backdrop-filter: blur(2px);
  overflow: auto;

  &__contain {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    background: var(--color-layout);
  }
}
