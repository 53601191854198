@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';

.debug {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 999999999;
  inset: 0 0 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  pointer-events: none;

  &__contain {
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-wrap: wrap;
    padding: 20px 20px 0 20px;
    max-width: 25vw;
    pointer-events: none;

    &__item {
      pointer-events: visible;

      margin-left: 20px;
      margin-bottom: 20px;
    }
  }
}
