@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/placeholder.scss';
// @import url('slick-carousel/slick/slick.css');
// @import url('slick-carousel/slick/slick-theme.css');

@mixin slider-contain() {
  padding: 20px;
}

@mixin slider-contain-item() {
  margin-right: 30px;

  &:last-of-type {
    margin-right: 0px;
  }
}

.slider {
  position: relative;

  &__slick {
    .slick-track {
      @include slider-contain();
      height: 100% !important;
      display: flex;
      justify-content: center;
      align-items: center;

      .slick-slide {
        margin: 0 20px;
      }
    }

    .slick-dots li {
      button::before {
        color: var(--color-disabled) !important;
      }

      &.slick-active button::before {
        color: var(--color-primary) !important;
      }

      button::before {
        font-size: 10px !important;
      }
    }

    .slick-arrow::before {
      content: '' !important;
    }

    .slick-prev,
    .slick-next {
      z-index: 1000 !important;
    }
    .slick-prev {
      left: 0px;
    }

    .slick-next {
      right: 0px;
    }
  }

  &__single {
    @include slider-contain();
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__draggable {
    @include slider-contain();
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none;
    height: 100%;
    width: 100%;

    &__item {
      @include slider-contain-item();
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}
