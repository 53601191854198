@import '@gimlite/watermelon/sass/utils/variable.scss';
@import '@gimlite/watermelon/sass/utils/function.scss';

.radiogroup {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  &--way {
    &--vertical {
      flex-direction: column;

      .radio {
        margin-bottom: var(--space) !important;
        &:last-of-type {
          margin: 0px;
        }
      }
    }

    &--horizontal {
      flex-direction: row;

      .radio {
        margin-right: var(--space) !important;
        &:last-of-type {
          margin: 0px;
        }
      }
    }
  }
}

.radio {
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-radio {
    box-shadow: $inputShadow;
  }

  &--size {
    @each $name, $item in $checkSize {
      &--#{$name} {
        .ant-radio-inner {
          height: $item !important;
          width: $item !important;
        }
      }
    }
  }

  .ant-radio-wrapper {
    margin: 0px !important;
  }

  &__label {
    margin-left: $space !important;
  }
}
