@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/mixin.scss';

.blurb {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3px;
    position: relative;

    &__item {
      margin-right: 3px;
      white-space: nowrap;
    }

    &__badges {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &__item {
        margin: 0 5px;
      }
    }
  }

  &__description {
    margin-bottom: 0px;
    white-space: nowrap;
  }

  &__subcontain {
    margin-top: 1px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
