@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/placeholder.scss';

$step-width: 92%;

.steps {
  &--single {
    justify-content: center !important;
  }
  .ant-steps-item-tail::after {
    width: #{$step-width} !important;
    transform: translateX(calc((100% - $step-width) / 2)) !important;
    height: 2px !important;
    border-radius: 10px !important;
  }

  .ant-steps-item-icon {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-width: 1px !important;
    border-style: solid !important;
    font-size: size(18px) !important;
    background-color: var(--color-background) !important;
  }

  .ant-steps-item-title {
    font-size: size(16px) !important;
  }

  .ant-steps-item-wait {
    .ant-steps-item-tail::after {
      background: var(--color-background) !important;
    }

    .ant-steps-item-title {
      color: var(--color-disabled) !important;
    }

    .ant-steps-icon {
      color: var(--color-disabled) !important;
    }

    .ant-steps-item-icon {
      background-color: var(--color-background) !important;
      border-color: var(--color-background) !important;
    }
  }

  .ant-steps-item-process {
    .ant-steps-item-tail::after {
      background: var(--color-background) !important;
    }

    .ant-steps-item-title {
      color: var(--color-success) !important;
    }

    .ant-steps-item-icon {
      background-color: var(--color-background) !important;
      border-color: var(--color-success) !important;
    }

    .ant-steps-icon,
    .ant-steps-icon svg {
      fill: var(--color-success) !important;
      color: var(--color-success) !important;
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-tail::after {
      background: var(--color-success) !important;
    }

    .ant-steps-item-title {
      color: var(--color-success) !important;
    }

    .ant-steps-item-icon {
      background-color: var(--color-success) !important;
    }

    .ant-steps-icon,
    .ant-steps-icon svg {
      fill: var(--color-yin) !important;
      color: var(--color-yin) !important;
    }
  }

  .ant-steps-item-content {
    margin-top: 5px !important;
  }

  .ant-steps-item-title {
    line-height: 1.2 !important;
    color: var(--color-yang) !important;
  }

  .ant-steps-icon {
    font-size: 18px !important;
  }
}
