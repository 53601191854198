@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../search/search.component.scss';

.hyperlink {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 10px;
  border-radius: 10px;
  background-color: var(--color-background);
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: var(--color-selected);
  }

  * {
    pointer-events: none;
  }

  &__text {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
