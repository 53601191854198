@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';

$writeColor: (
  error: var(--color-error),
  warn: var(--color-warn),
  success: var(--color-success),
  disabled: var(--color-disabled),
  primary: var(--color-primary),
  secondary: var(--color-primary),
  black: var(--color-black),
  white: var(--color-white),
  primary-over: var(--color-primary-over),
  text: var(--color-text),
  label: var(--color-label),
  info: var(--color-info),
  yin: var(--color-yin),
  yang: var(--color-yang),
);

.write {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  pointer-events: visible;
  position: relative;

  &--lineHeight {
    &--min {
      line-height: 1 !important;
    }

    &--max {
      line-height: initial !important;
    }
  }

  &--copyPaste {
    padding: 10px;
    background-color: var(--color-background);
    border-radius: 10px;
    overflow: hidden;

    .write__copyPaste {
      position: absolute;
      width: 100%;
      height: 100%;
      inset: 0 0 0 0;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--color-primary);
      opacity: 0;
      transition: all 0.3s ease-in-out;

      &:hover {
        opacity: 1;
      }

      span {
        white-space: wrap;
        color: var(--color-primary-over);
        font-weight: bold;
        font-size: 15px;
      }
    }
  }

  &__item {
    color: inherit;
    overflow: visible;
    font-size: inherit;
    font-weight: inherit;
    font-style: inherit;
    line-height: inherit;
    text-align: inherit;
    pointer-events: none;
    font-family: 'Inter', sans-serif;
  }

  &--mode {
    @each $name, $item in $writeMode {
      &--#{$name} {
        font-size: map-get($item, font-size);
        font-weight: map-get($item, font-weight);
        font-style: map-get($item, font-style);
        color: map-get($item, color);
      }
    }
  }

  &--color {
    @each $name, $item in $writeColor {
      &--#{$name} {
        color: $item;
      }
    }
  }
}
