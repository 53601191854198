@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/placeholder.scss';

$switchColor: (
  error: var(--color-error),
  warn: var(--color-warn),
  success: var(--color-success),
);

.switch {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &__text {
    margin-right: 5px;
  }

  &--width {
    @each $name, $item in map-get($entry, width) {
      &--#{$name} {
        width: #{$item};
      }
    }
  }

  &--color--active {
    @each $name, $item in $switchColor {
      &--#{$name} {
        .ant-switch.ant-switch-checked {
          background-color: $item !important;
        }
      }
    }
  }

  &--color--inactive {
    @each $name, $item in $switchColor {
      &--#{$name} {
        .ant-switch:not(.ant-switch-checked) {
          background-color: $item !important;
        }
      }
    }
  }
}
