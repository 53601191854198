@import '@gimlite/watermelon/sass/utils/variable.scss';
@import '@gimlite/watermelon/sass/utils/function.scss';
@import '@gimlite/watermelon/sass/utils/mixin.scss';

.zoneModalRead,
.zoneModalEdit {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  background-color: var(--color-layout);
  padding: 12px;
  border-radius: 8px;
  border: 2px solid var(--color-border);
}

.zoneModalRead {
  min-width: 380px;

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 24px;

    &__present {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      width: 100%;

      &__color {
        width: 36px;
        aspect-ratio: 1/1;
        border-radius: 100%;
      }

      &__text {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        &__name {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 8px;
        }
      }
    }

    &__action {
      display: flex;
      justify-content: center;
      align-items: center;

      &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}

.zoneModalEdit {
  width: 350px;

  &__head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      gap: 8px;
    }
  }
}

.zoneModalClose {
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: visible !important;

  cursor: pointer;

  * {
    pointer-events: none;
  }
}
