@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/mixin.scss';

.search {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 5px 2px;
  background-color: var(--color-background);

  .autocomplete-node {
    width: 100% !important;
    height: 100% !important;
    outline: none !important;
    border: none !important;
    font-size: inherit !important;

    * {
      padding: 0px !important;
    }

    .ant-select-selection-search {
      inset-inline-start: 0px !important;
    }

    .ant-select-selection-search-input {
      height: 100% !important;
    }

    *,
    &:hover,
    &:focus,
    &:focus-visible,
    &:target,
    &:focus-within,
    *:hover,
    *:focus,
    *:focus-visible,
    *:target,
    *:focus-within {
      border: none !important;
      outline: none !important;
      font-size: inherit !important;
    }
  }

  &--width {
    @each $name, $item in map-get($entry, width) {
      &--#{$name} {
        width: #{$item} !important;
      }
    }
  }

  &--height {
    @each $name, $item in map-get($entry, height) {
      &--#{$name} {
        .search__input {
          height: #{$item} !important;
        }

        // .search__icon,
        // .search__icon svg {
        //   height: calc(#{$item} - 9px) !important;
        // }
      }
    }
  }

  &__icon {
    margin: 0 8px 0px 5px;
  }

  &__input {
    font-size: map-get(map-get($writeMode, value-small), font-size);
    font-weight: map-get(map-get($writeMode, value-small), font-weight);
    font-style: map-get(map-get($writeMode, value-small), font-style);
    color: map-get(map-get($writeMode, value-small), color);
    background-color: transparent;
    width: 100%;
    outline: none;

    &::placeholder {
      color: var(--color-disabled);
      font-weight: 500;
      font-size: size(15px);
      line-height: 1;
    }
  }
}
