@import '@gimlite/watermelon/sass/utils/variable.scss';
@import '@gimlite/watermelon/sass/utils/function.scss';
@import '@gimlite/watermelon/sass/utils/mixin.scss';

.confirmStep-banner-2fa {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &__code {
    font-size: size(40px);
    color: var(--color-primary);
    font-weight: bold;
  }
}
