@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/mixin.scss';

.durationpicker {
  padding: map-get($entry, padding, y) map-get($entry, padding, x) !important;
  // box-shadow: $inputShadow;

  * {
    font-size: map-get($writeMode, input, font-size) !important;
    font-weight: map-get($writeMode, input, font-weight) !important;
    font-style: map-get($writeMode, input, font-style) !important;
  }

  &--width {
    @each $name, $item in map-get($entry, width) {
      &--#{$name} {
        width: #{$item} !important;
      }
    }
  }

  &--height {
    @each $name, $item in map-get($entry, height) {
      &--#{$name} {
        height: #{$item} !important;
      }
    }
  }
}

.ant-picker-time-panel-column {
  @include scrollbar();
}

.ant-picker-dropdown {
  * {
    font-size: size(14px) !important;
  }
}
