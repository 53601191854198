@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/mixin.scss';

$segmentedSize: (
  xsmall: (
    padding: size(2px) size(10px),
    font-size: size(13px),
  ),
  small: (
    padding: size(4px) size(10px),
    font-size: size(14px),
  ),
  medium: (
    padding: size(5px) size(10px),
    font-size: size(15.5px),
  ),
  large: (
    padding: size(9px) size(10px),
    font-size: size(17px),
  ),
  xlarge: (
    padding: size(12px) size(10px),
    font-size: size(18px),
  ),
);

.segmented {
  width: 100%;
  background-color: var(--color-background) !important;

  &--size {
    @each $name, $item in $segmentedSize {
      &--#{$name} {
        .ant-segmented-item {
          padding: map-get($item, padding);
          font-size: map-get($item, font-size);
        }
      }
    }
  }

  // background-color: var(--color-yin-alpha-25) !important;

  .ant-segmented-group {
    border-radius: 5px;
  }

  .ant-segmented-item {
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      color: var(--color-primary-over) !important;
    }

    &::after {
      transition: all 0.5s ease-in-out !important;
      // background-color: transparent !important;
    }
  }

  .ant-segmented-item-label {
    font-weight: 500;
    min-height: initial !important;
  }

  .ant-segmented-item-selected {
    background-color: var(--color-primary) !important;

    .ant-segmented-item-label {
      color: var(--color-primary-over) !important;
    }
  }
}
