@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';

.useCSV-example-download {
  opacity: 0;
  position: absolute;
  inset: 0 0 0 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: var(--color-layout);
  backdrop-filter: blur(10px);
  transition: opacity 0.4s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  * {
    pointer-events: none;
  }

  &__icon {
    margin-bottom: 10px;
  }

  &:hover {
    opacity: 0.8;
  }
}
