@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';

.filter-ex {
  &__contain {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr min-content;

    &--vertical {
      &--start {
        align-items: flex-start;
      }

      &--center {
        align-items: center;
      }

      &--end {
        align-items: flex-end;
      }
    }

    &--free {
      width: 100%;

      .filter-ex__contain__actions,
      .filter-ex__contain__actions__item,
      .filter-ex__contain__fields,
      .filter-ex__contain__fields__item {
        padding: 0px;
        margin: 0px;
        width: 100%;
      }

      .filter-ex__contain__actions {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 7px;
      }
    }

    &__actions,
    &__fields {
      &__item {
        padding: 5px 0px;
      }
    }

    &__fields {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      margin-right: $space;
      width: 100%;

      &__item {
        box-sizing: border-box;
        margin-right: calc($space * 2);
      }
    }

    &__actions {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      &__item {
        margin-left: $space;
      }
    }
  }
}

.filter-re {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 10px 8px 0px 0px;

  &-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 450px;

    &__contain {
      display: flex;
      flex-direction: column;
      width: 100%;

      &__item {
        margin-bottom: 10px;
      }
    }

    &__action {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__count {
    height: size(19px);
    width: size(19px);
    background-color: var(--color-info);
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    opacity: 1;

    &__item {
      font-size: size(11px);
      font-weight: 600;
      color: var(--color-info-over);
    }
  }

  &__icon {
  }
}
