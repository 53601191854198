@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';

.checkbox {
  transform-origin: center center;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .ant-checkbox,
  .ant-checkbox-wrapper {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .ant-checkbox-inner {
    box-shadow: $inputShadow;
  }

  //! L'icone check n'est pas droit !
  // .ant-checkbox-inner::after {
  //   right: 50% !important;
  //   top: 50% !important;
  //   inset-inline-start: 0px !important;
  // }

  &--size {
    @each $name, $item in $checkSize {
      &--#{$name} {
        .ant-checkbox-inner {
          height: $item !important;
          width: $item !important;
        }
        // transform: scale(#{$item});
      }
    }
  }
}
