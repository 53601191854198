@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';

.input {
  display: flex;
  justify-content: flex-start;

  * {
    font-size: map-get($writeMode, input, font-size) !important;
    font-weight: map-get($writeMode, input, font-weight) !important;
    font-style: map-get($writeMode, input, font-style) !important;
  }

  &--backgroundColor {
    @each $name, $item in map-get($entry, background-color) {
      &--#{$name} {
        .ant-input {
          color: map-get($item, color);
          background-color: map-get($item, background-color);

          &.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
            background: map-get($item, background-color) !important;
          }

          &::placeholder {
            color: var(--color-disabled);
          }
        }
      }
    }
  }

  &--width {
    @each $name, $item in map-get($entry, width) {
      &--#{$name} {
        width: #{$item};
      }
    }
  }

  &--minWidth {
    @each $name, $item in map-get($entry, width) {
      &--#{$name} {
        min-width: #{$item};
      }
    }
  }

  &--height {
    @each $name, $item in map-get($entry, height) {
      &--#{$name} {
        height: #{$item} !important;
      }
    }
  }

  input {
    padding: map-get($entry, padding, y) map-get($entry, padding, x) !important;
  }

  &__item {
    width: 100%;
    // box-shadow: $inputShadow;
  }

  &__button {
    width: 20px !important;

    &--before {
      border-bottom-right-radius: 0px !important;
      border-top-right-radius: 0px !important;
    }

    &--after {
      border-bottom-left-radius: 0px !important;
      border-top-left-radius: 0px !important;
    }
  }
}

.ant-input-group-addon {
  cursor: pointer;
  background-color: var(--color-primary);
}

.ant-input-number-input-wrap,
.ant-input-number-input {
  height: 100% !important;
}
