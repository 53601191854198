@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/mixin.scss';

.appLoader {
  position: relative;
  width: 100vw;
  height: 100vh;

  &__content {
    position: absolute;
    inset: 0 0 0 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;

    &--progress {
      .appLoader__content__logo {
        @keyframes pulsation {
          0% {
            transform: scale(1);
            opacity: 1;
          }
          50% {
            transform: scale(1.15);
            opacity: 0.75;
          }
          100% {
            transform: scale(1);
            opacity: 1;
          }
        }

        animation: pulsation 1s ease-in-out infinite;
      }
    }

    &--stop {
      @keyframes demise {
        0% {
          opacity: 1;
        }

        100% {
          opacity: 0;
          pointer-events: none;
        }
      }

      animation: demise 0.8s ease-in-out forwards;
    }

    &__logo {
      height: 60px !important;

      svg,
      img {
        height: 100% !important;
      }

      * {
        fill: inherit !important;
        color: inherit !important;
      }
    }
  }
}
