@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/placeholder.scss';

$headerPosition: (
  absolute: absolute,
  fixed: fixed,
  static: static,
  sticky: sticky,
);

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: size(45px);
  padding: 0px 15px;
  top: 0px;
  z-index: 1000;
  background-color: var(--color-primary);

  &--position {
    @each $name, $item in $headerPosition {
      &--#{$name} {
        position: $item;
      }
    }
  }

  &__logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 10px;

    svg {
      height: size(35px);
      width: 100%;
    }

    &__separa {
      height: 7px;
      width: 7px;
      border-radius: 30px;
    }

    &__list {
      padding-left: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      height: 100%;

      &__item {
        cursor: pointer;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 5px 5px 0px 0px;
      }
    }
  }

  &__extra {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    max-height: 100%;
    height: 100%;
    width: 100%;

    @media screen and (max-width: map-get($device, desktop)) {
      justify-content: flex-end;
    }
  }

  &__params {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;

    &__item {
      gap: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.accountSwitchApp {
  height: 320px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;

  &__body {
    padding: 15px;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(2, 1fr);
    align-content: flex-start;
    gap: 10px;
    overflow-x: hidden;

    &__app {
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      aspect-ratio: 1;
      width: 110px;

      * {
        pointer-events: none;
      }

      &--inactive {
        background-color: var(--color-intermediate) !important;

        * {
          color: var(--color-disabled) !important;
        }
      }

      &--active {
        cursor: pointer;
        transition: all 0.2s ease-in-out;
      }
    }
  }

  &__footer {
    background-color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px;
    cursor: pointer;
    gap: 10px;

    * {
      pointer-events: none;
    }
  }
}

.select-lang {
  &,
  * {
    border: 0px solid transparent !important;
    outline: none !important;
  }

  .ant-select-selector .ant-select-selection-item {
    display: flex !important;
  }

  &-item {
    display: flex !important;
    justify-content: flex-start;
    align-items: center;

    img {
      height: 20px;
      margin-right: 7px;
    }
  }
}

.accountManager {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &__account {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    &__identity {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;

      &__names {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }

    &__group {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      border-bottom: 1px solid var(--color-border);
      padding: 10px 0px;

      &:last-child {
        border-bottom: none;
      }

      &__option {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 7px 10px;
        position: relative;
        border-radius: var(--border-radius);

        &:has(.event-trigger, .drawertrigger) {
          transition: background-color 0.2s ease-in-out;

          * {
            transition: color 0.2s ease-in-out;
          }

          &:hover {
            background-color: var(--color-selected);

            * {
              color: var(--color-primary) !important;
            }
          }
        }

        &__subject {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          &__icon {
            margin-right: 10px;
          }

          &__label {
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }
        }

        &__extra {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 100%;
          height: 100%;

          .event-trigger,
          .drawertrigger {
            position: absolute;
            inset: 0 0 0 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            cursor: pointer !important;
            padding-right: 10px;
          }
        }
      }
    }
  }
}

.accountPresent {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
