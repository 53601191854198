@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';

.empty {
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .ant-empty-image {
    svg {
      height: size(35px) !important;
    }
    width: 100% !important;
  }
  .ant-empty-description {
    margin-top: 5px !important;
    text-align: center !important;
  }

  &__commingSoon,
  &__maintenance,
  &__disabled {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__pageNotFound {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
