@import '@gimlite/watermelon/sass/utils/variable.scss';
@import '@gimlite/watermelon/sass/utils/function.scss';
@import '@gimlite/watermelon/sass/utils/mixin.scss';

.formListButtonContain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: map-get(map-get($entry, height), xlarge);
}
