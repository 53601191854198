@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/mixin.scss';
@import '../../sass/utils/placeholder.scss';

$widget-padding-unclouple: size(15px);

.widget,
.widgetgroup {
  position: relative;
  border-radius: 3px;
  width: 100%;
  @extend %layout;

  &--error {
    background-color: var(--color-primary-alpha-20);
  }

  @media screen and (max-width: map-get($device, desktop)) {
    display: flex;
    flex-direction: column;
    height: initial;
  }

  @media screen and (min-width: map-get($device, desktop)) {
    display: grid;
    //! grid-template-rows en JS
    grid-template-columns: 1fr;
    overflow: auto;
    height: 100%;
  }

  &__contain {
    overflow: hidden;
    width: 100%;
    z-index: 2;
    height: 100%;

    @media screen and (max-width: map-get($device, desktop)) {
      display: flex;
      flex-direction: column;
    }

    &.widget-without-title {
      padding-top: $widget-padding-unclouple;
    }

    &.widget-padding {
      padding: 0px $widget-padding-unclouple $widget-padding-unclouple
        $widget-padding-unclouple;
    }

    &.widget-responsive-nopadding {
      @media screen and (max-width: map-get($device, desktop)) {
        padding: 0px;
      }
    }
  }
}

.widgetgroup {
  @media screen and (min-width: map-get($device, desktop)) {
    height: 100%;
  }

  @media screen and (min-width: map-get($device, desktop)) {
    height: 100%;
  }

  @media screen and (min-width: map-get($device, desktop)) {
    height: 100%;
  }

  .widget {
    margin: 0px;
    box-shadow: none;
    background: transparent;
    max-width: 100%;

    .wid-header {
      padding-top: 0px;
      padding-bottom: 0px;
    }
    &__contain {
      padding: 0px;
    }
  }
}

.widget {
  box-sizing: border-box;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

.wid-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: size(15px) $widget-padding-unclouple;
  margin-bottom: size(10px);

  &-background {
    padding: 10px $widget-padding-unclouple;
    background-color: var(--color-primary);

    * {
      line-height: initial !important;
    }
  }

  @media screen and (max-width: map-get($device, desktop)) {
    padding-left: map-get(map-get($mobile, component), padding-x);
    padding-right: map-get(map-get($mobile, component), padding-x);
  }

  &__icon,
  &__title,
  &__extra {
  }

  &__extra {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon,
  &__title {
    margin-right: 12px;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    margin-right: 10px;

    // * {
    //   color: var(--color-primary);
    //   font-size: size(25px);
    // }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &__text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      &__title {
        z-index: 2;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
      }
    }

    &__action {
      display: flex;
      justify-content: center;
      align-items: center;

      &__item {
        margin: 0 10px;
      }
    }
  }
}

.errorRequest {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0px 12px 12px 12px;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 250px;
    min-height: 140px;
    gap: 8px;

    &__present {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }

    &__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;
    }
  }
}

.errorRefetch {
  cursor: pointer;

  * {
    pointer-events: none;
  }
}
