@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/placeholder.scss';

.result {
  .ant-result {
    padding: 0px !important;

    .ant-result-icon {
      svg {
        height: size(70px) !important;
      }
    }
    .ant-result-title {
      font-size: size(20px);
    }
  }
}
