@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/mixin.scss';

.zone {
  display: grid;
  //! grid-template-rows en JS
  //! grid-template-columns en JS
  height: 100%;
  overflow: auto;
  @include scrollbar();

  &--space {
    grid-gap: $space;
  }
}

.zonearea {
  overflow: hidden;
  position: relative;

  &__error {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
