* {
  margin: 0;
  padding: 0;
  // line-height: 1;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  list-style: none;
  font-weight: normal;
}
