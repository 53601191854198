@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/mixin.scss';
@import '../../sass/utils/placeholder.scss';

.list-header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid var(--color-border);
  padding: 0px size(10px) size(10px) size(10px);
  position: sticky;
  top: 0px;
  background-color: var(--color-layout);

  &__contain {
    width: 100%;
    display: grid;
    margin-right: 20px;
    grid-template-rows: 1fr;

    &__item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &__filter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.listflat {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;

  &--border {
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius);
  }

  &__contain {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    @include scrollbar();

    &__line {
      display: grid;
      padding: size(10px) size(8px);
      width: 100%;
      border-bottom: 1px solid var(--color-border);
      align-content: center;
      align-items: center;
      gap: 0 size(10px);

      &--selected {
        background-color: var(--color-selected);
      }

      &--click {
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        &:hover {
          background-color: var(--color-over);
        }
      }

      &:last-of-type {
        border-bottom: none;
      }

      @media screen and (max-width: map-get($device, desktop)) {
        padding-left: map-get(map-get($mobile, component), padding-x);
        padding-right: map-get(map-get($mobile, component), padding-x);
      }
    }
  }
}

.listflat-radiogroup {
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
  height: 100% !important;
  justify-content: space-between !important;
  align-items: flex-end !important;
}
