@import './function.scss';

$device: (
  min: 350px,
  mobile: 500px,
  desktop: 1249px,
  max: 2000px,
);

:root {
  @each $name, $item in $device {
    --device-#{$name}: #{$item};
  }
}

$breakpoint: (
  navigation: 1400px,
);

:root {
  @each $name, $item in $breakpoint {
    --breakpoint-#{$name}: #{$item};
  }
}

$border: (
  line: 0.5px solid var(--color-border),
  radius: 6px,
);

:root {
  @each $name, $item in $border {
    --border-#{$name}: #{$item};
  }
}

$space: size(10px);

:root {
  --space: #{$space};
}

$entry: (
  width: (
    initial: 'initial',
    xsmall: size(70px),
    small: size(100px),
    xmedium: size(150px),
    medium: size(180px),
    large: size(260px),
    xlarge: size(320px),
    full: 100%,
  ),
  height: (
    initial: 'initial',
    xsmall: size(24px),
    small: size(26px),
    xmedium: size(27px),
    medium: size(29px),
    large: size(32px),
    xlarge: size(37px),
    full: 100%,
  ),
  padding: (
    x: size(8px),
    y: 0px,
  ),
  background-color: (
    yin: (
      color: var(--color-yang),
      background-color: var(--color-yin),
    ),
    yang: (
      color: var(--color-yin),
      background-color: var(--color-yang),
    ),
    white: (
      color: var(--color-black),
      background-color: var(--color-white),
    ),
    black: (
      color: var(--color-white),
      background-color: var(--color-black),
    ),
  ),
);

$writeMode: (
  title-big: (
    font-size: size(60px),
    font-weight: 600,
    font-style: normal,
    color: var(--color-text),
  ),
  title-xlarge: (
    font-size: size(40px),
    font-weight: 600,
    font-style: normal,
    color: var(--color-text),
  ),
  title-large: (
    font-size: size(25px),
    font-weight: 600,
    font-style: normal,
    color: var(--color-text),
  ),
  title-medium: (
    font-size: size(22px),
    font-weight: 600,
    font-style: normal,
    color: var(--color-text),
  ),
  title-small: (
    font-size: size(19px),
    font-weight: 600,
    font-style: normal,
    color: var(--color-text),
  ),
  title-small-description: (
    font-size: size(15px),
    font-weight: 400,
    font-style: normal,
    color: var(--color-label),
  ),
  cell-value: (
    font-size: size(13px),
    font-weight: 500,
    font-style: normal,
    color: var(--color-text),
  ),
  cell-title: (
    font-size: size(13px),
    font-weight: 600,
    font-style: normal,
    color: var(--color-text),
  ),
  namise: (
    font-size: size(15px),
    font-weight: 600,
    font-style: normal,
    color: var(--color-text),
  ),
  namise-description: (
    font-size: size(13px),
    font-weight: 400,
    font-style: normal,
    color: var(--color-label),
  ),
  label-value: (
    font-size: size(13px),
    font-weight: 500,
    font-style: normal,
    color: var(--color-text),
  ),
  label: (
    font-size: size(13px),
    font-weight: 400,
    font-style: normal,
    color: var(--color-label),
  ),
  value-large: (
    font-size: size(18px),
    font-weight: 400,
    font-style: normal,
    color: var(--color-text),
  ),
  value-medium: (
    font-size: size(16px),
    font-weight: 400,
    font-style: normal,
    color: var(--color-text),
  ),
  value-small: (
    font-size: size(14px),
    font-weight: 400,
    font-style: normal,
    color: var(--color-text),
  ),
  input: (
    font-size: size(14px),
    font-weight: 400,
    font-style: normal,
    color: var(--color-text),
  ),
  key-small-bold: (
    font-size: size(12px),
    font-weight: 600,
    font-style: normal,
    color: var(--color-text),
  ),
  key-small-regular: (
    font-size: size(12px),
    font-weight: 400,
    font-style: normal,
    color: var(--color-text),
  ),
  key-small-light: (
    font-size: size(12px),
    font-weight: 300,
    font-style: normal,
    color: var(--color-text),
  ),
  link: (
    font-size: size(14px),
    font-weight: 600,
    font-style: normal,
    color: var(--color-text),
  ),
);

$write2Mode: (
  x-large-title: (
    font-size: size(56px),
    font-weight: 600,
    font-style: normal,
    color: var(--color-text),
    line-height: 120%,
  ),
  large-title: (
    font-size: size(36px),
    font-weight: 600,
    font-style: normal,
    color: var(--color-text),
    line-height: 120%,
  ),
  title-1: (
    font-size: size(32px),
    font-weight: 600,
    font-style: normal,
    color: var(--color-text),
    line-height: 120%,
  ),
  title-2: (
    font-size: size(28px),
    font-weight: 600,
    font-style: normal,
    color: var(--color-text),
    line-height: 120%,
  ),
  title-3: (
    font-size: size(20px),
    font-weight: 400,
    font-style: normal,
    color: var(--color-text),
    line-height: 120%,
  ),
  headline: (
    font-size: size(20px),
    font-weight: 600,
    font-style: normal,
    color: var(--color-text),
    line-height: 150%,
  ),
  button: (
    font-size: size(16px),
    font-weight: 500,
    font-style: normal,
    color: var(--color-text),
    line-height: 150%,
  ),
  body: (
    font-size: size(14px),
    font-weight: 500,
    font-style: normal,
    color: var(--color-text),
    line-height: 150%,
  ),
  label: (
    font-size: size(12px),
    font-weight: 600,
    font-style: normal,
    color: var(--color-text),
    line-height: 150%,
  ),
  subhead: (
    font-size: size(14px),
    font-weight: 500,
    font-style: normal,
    color: var(--color-text),
    line-height: 150%,
  ),
  link: (
    font-size: size(14px),
    font-weight: 500,
    font-style: normal,
    color: var(--color-text),
    line-height: 150%,
  ),
  headcaption: (
    font-size: size(12px),
    font-weight: 500,
    font-style: normal,
    color: var(--color-text),
    line-height: 150%,
  ),
  caption: (
    font-size: size(12px),
    font-weight: 500,
    font-style: normal,
    color: var(--color-text),
    line-height: 150%,
  ),
  sublabel: (
    font-size: size(11px),
    font-weight: 500,
    font-style: normal,
    color: var(--color-text),
    line-height: 150%,
  ),
);

$checkSize: (
  xsmall: size(12px),
  small: size(16px),
  medium: size(20px),
  large: size(22.5px),
  xlarge: size(25px),
);

$mobile: (
  component: (
    padding-x: 15px,
  ),
);

$iconColor: (
  error: var(--color-error),
  warn: var(--color-warn),
  success: var(--color-success),
  info: var(--color-info),
  disabled: var(--color-disabled),
  primary: var(--color-primary),
  black: var(--color-black),
  white: var(--color-white),
  label: var(--color-label),
  yin: var(--color-yin),
  yang: var(--color-yang),
  text: var(--color-text),
  default: var(--color-text),
  primary-over: var(--color-primary-over),
  deep_indigo: var(--color-deep_indigo),
  deep_indigo_70: var(--color-deep_indigo_70),
  deep_indigo_50: var(--color-deep_indigo_50),
  deep_indigo_30: var(--color-deep_indigo_30),
  deep_indigo_10: var(--color-deep_indigo_10),
  deep_indigo_4: var(--color-deep_indigo_4),
  soft_teal: var(--color-soft_teal),
  soft_teal_70: var(--color-soft_teal_70),
  soft_teal_50: var(--color-soft_teal_50),
  soft_teal_30: var(--color-soft_teal_30),
  soft_teal_10: var(--color-soft_teal_10),
  black_90: var(--color-black_90),
  black_70: var(--color-black_70),
  black_50: var(--color-black_50),
  black_15: var(--color-black_15),
  black_4: var(--color-black_4),
  black_2: var(--color-black_2),
  alert_success: var(--color-alert_success),
  alert_success_50: var(--color-alert_success_50),
  alert_warning: var(--color-alert_warning),
  alert_warning_50: var(--color-alert_warning_50),
  alert_advisory: var(--color-alert_advisory),
  alert_advisory_50: var(--color-alert_advisory_50),
  alert_error: var(--color-alert_error),
  alert_error_50: var(--color-alert_error_50),
  alert_info: var(--color-alert_info),
  alert_info_50: var(--color-alert_info_50),
);

$inputShadow: inset 0px 0px 3px 0px var(--color-shadow);

@each $name, $item in $iconColor {
  .#{$name} {
    fill: #{$item} !important;
  }
}
