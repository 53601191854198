@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';

.skeleton {
  &__line {
    width: 100% !important;

    &--small {
      height: 10px !important;
    }

    &--medium {
      height: 20px !important;
    }

    &--large {
      height: 30px !important;
    }

    .anticon {
      display: none !important;
    }
  }

  &__block {
    height: 100% !important;
    min-height: 350px !important;
    width: 100% !important;

    .anticon {
      display: none !important;
    }
  }
}

.ant-skeleton {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
