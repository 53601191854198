@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
$rowWidth: (
  initial: initial,
  full: 100%
);

$rowHeight: (
  initial: initial,
  full: 100%
);

.row {
  display: flex;

  &--width {
    @each $name, $item in $rowWidth {
      &--#{$name} {
        width: $item;
      }
    }
  }

  &--height {
    @each $name, $item in $rowHeight {
      &--#{$name} {
        height: $item;
      }
    }
  }
}
