@import '../utils/variable.scss';
@import '../utils/mixin.scss';
@import '../base/font.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
  @media screen and (max-width: map-get($device, desktop)) {
    background-color: var(--color-layout);
  }

  @media screen and (min-width: map-get($device, desktop)) {
    background-color: var(--color-background);
  }
}

html,
body,
#root {
  height: 100% !important;
}

#root {
  display: grid;
  grid-template: 1fr / 1fr;
  overflow-x: hidden;
  overflow-y: auto;
  @include scrollbar();

  @media screen and (max-width: map-get($device, desktop)) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
a,
li,
label,
tr,
td,
th,
input,
textarea,
li,
select,
pre,
option {
  line-height: initial;
  font-family: 'Inter';
}

:root {
  font-size: 16px;

  @media screen and (max-width: 2350px) {
    font-size: 15px;
  }
}

html {
  @include scrollbar();
}
