@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/placeholder.scss';

$descriptionSize: (
  small: 14px,
  medium: 16px,
  large: 18px,
  xlarge: 20px
);

.descriptiontext {
  display: flex;
  align-content: flex-start;
  grid-template-columns: repeat(3, 1fr);
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;

  &--layout {
    &--row {
      flex-direction: row;

      .descriptiontext__item {
        margin-right: 30px;

        &:last-of-type {
          margin-right: 0px;
        }
      }
    }
    &--column {
      flex-direction: column;
    }
  }

  &__item {
    display: flex;
    height: min-content;

    // * {
    //   line-height: 18px;
    // }

    &--way {
      &--vertical {
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;

        .descriptiontext__item__label:first-of-type {
          margin-bottom: 5px;
        }
      }
      &--horizontal {
        justify-content: center;
        align-items: center;
        flex-direction: row;
      }
    }

    &--size {
      @each $name, $item in $descriptionSize {
        &--#{$name} span {
          line-height: size($item);
        }

        &--#{$name} .descriptiontext__item__label {
          font-size: size($item);
        }

        &--#{$name} {
          margin: 5px 0;
        }
      }
    }

    &__label {
      margin-right: 5px;
    }

    &__value {
    }
  }
}

.descriptionline {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid var(--color-border);
    padding: 10px 8px;
  }
}
