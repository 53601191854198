@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';

$imageSize: (
  cell: 35px,
  xsmall: 38px,
  small: 50px,
  medium: 100px,
  large: 150px,
  full: 100%,
);

.image {
  &--full {
    height: 100%;
    width: 100%;

    .image--backgroud {
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 1;
      inset: 0 0 0 0;
    }
  }

  .ant-image {
    height: 100% !important;
    width: 100% !important;
    z-index: 2 !important;
  }

  &__item {
    width: auto !important;

    @each $name, $item in $imageSize {
      &--#{$name},
      &--#{$name} * {
        height: $item !important;
      }
    }

    &--full,
    &--full * {
      object-fit: contain;
      object-position: center center;
      width: 100% !important;
    }
  }

  &__empty {
    height: 100%;
    width: 100%;
    border: 1px dashed var(--color-primary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    * {
      color: var(--color-primary);
    }

    &__icon {
      * {
        font-size: size(250px) !important;
      }
    }

    &__text {
      font-size: size(18px) !important;
    }
  }
}
