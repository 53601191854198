@font-face {
  font-family: 'Calibri';
  src: url('../../fonts/calibri/light/Calibri-Light.eot');
  src: url('../../fonts/calibri/light/Calibri-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/calibri/light/Calibri-Light.woff2') format('woff2'),
    url('../../fonts/calibri/light/Calibri-Light.woff') format('woff'),
    url('../../fonts/calibri/light/Calibri-Light.ttf') format('truetype'),
    url('../../fonts/calibri/light/Calibri-Light.svg#Calibri-Light')
      format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Calibri';
  src: url('../../fonts/calibri/bold/Calibri-Bold.eot');
  src: url('../../fonts/calibri/bold/Calibri-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/calibri/bold/Calibri-Bold.woff2') format('woff2'),
    url('../../fonts/calibri/bold/Calibri-Bold.woff') format('woff'),
    url('../../fonts/calibri/bold/Calibri-Bold.ttf') format('truetype'),
    url('../../fonts/calibri/bold/Calibri-Bold.svg#Calibri-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Calibri';
  src: url('../../fonts/calibri/regular/Calibri.eot');
  src: url('../../fonts/calibri/regular/Calibri.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/calibri/regular/Calibri.woff2') format('woff2'),
    url('../../fonts/calibri/regular/Calibri.woff') format('woff'),
    url('../../fonts/calibri/regular/Calibri.ttf') format('truetype'),
    url('../../fonts/calibri/regular/Calibri.svg#Calibri') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
