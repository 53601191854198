@import './variable.scss';
@import './function.scss';
@import './mixin.scss';

%card {
  // box-shadow: 0px 0px 4px var(--color-shadow);
  background: var(--color-card);
  border-radius: 5px;
  border: 1px solid var(--color-border);
}

%layout {
  @media screen and (min-width: map-get($device, desktop)) {
    box-shadow: 0px 0px 4px var(--color-shadow);
  }
  background: var(--color-layout);
}
