@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';

$loadingSize: (
  small: 50px,
  medium: 100px,
  large: 200px,
);

.loading {
  &__size {
    @each $name, $item in $loadingSize {
      &--#{$name} .anticon {
        font-size: size($item) !important;
      }
    }
  }
}
