@import './variable.scss';

@mixin scrollbar() {
  $scroll-track: var(--color-primary);
  $scroll-width: 3px;
  $scroll-radius: 15px;

  //* Firefox, Chrome 2024
  scrollbar-color: $scroll-track transparent;
  scrollbar-width: thin;

  //* Autres
  &::-webkit-scrollbar {
    width: $scroll-width !important;
    position: absolute !important;
    mix-blend-mode: exclusion !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scroll-track !important;
    border-radius: $scroll-radius;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}
