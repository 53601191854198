@import '@gimlite/watermelon/sass/utils/variable.scss';
@import '@gimlite/watermelon/sass/utils/function.scss';

.maps {
  width: 100%;
  height: 100%;

  &__contain {
    height: 100%;
    width: 100%;
    display: flex;

    &__canvas {
      width: 100%;
      height: 100%;
      touch-action: pan-x pan-y;
      flex: 2.5;
    }

    .maps-editor {
      flex: 1;
    }
  }
}

.maps-marker-label {
  background-color: var(--color-primary);
  border-radius: 3px;
  padding: 2px 6px;
  color: var(--color-primary-over);
}

.gm-style-iw {
  background-color: var(--color-background) !important;
  padding: 0px !important;

  .gm-style-iw-ch,
  .gm-style-iw-d {
    overflow: auto !important;
  }

  .gm-style-iw-chr {
    display: none !important;

    button.gm-ui-hover-effect {
      height: 40px !important;
      width: 40px !important;

      span {
        height: 20px !important;
        width: 20px !important;
      }
    }
  }

  .gm-style-iw-d {
    padding: 0px !important;
  }
}

.gmnoprint {
  button {
    min-height: 32px !important;
    min-width: 32px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    overflow: hidden !important;
  }
}
