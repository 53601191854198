@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';

$writeColor: (
  deep_indigo: var(--color-deep_indigo),
  deep_indigo_70: var(--color-deep_indigo_70),
  deep_indigo_50: var(--color-deep_indigo_50),
  deep_indigo_30: var(--color-deep_indigo_30),
  deep_indigo_10: var(--color-deep_indigo_10),
  deep_indigo_4: var(--color-deep_indigo_4),
  soft_teal: var(--color-soft_teal),
  soft_teal_70: var(--color-soft_teal_70),
  soft_teal_50: var(--color-soft_teal_50),
  soft_teal_30: var(--color-soft_teal_30),
  soft_teal_10: var(--color-soft_teal_10),
  black_90: var(--color-black_90),
  black_70: var(--color-black_70),
  black_50: var(--color-black_50),
  black_15: var(--color-black_15),
  black_4: var(--color-black_4),
  black_2: var(--color-black_2),
  alert_success: var(--color-alert_success),
  alert_success_50: var(--color-alert_success_50),
  alert_warning: var(--color-alert_warning),
  alert_warning_50: var(--color-alert_warning_50),
  alert_advisory: var(--color-alert_advisory),
  alert_advisory_50: var(--color-alert_advisory_50),
  alert_error: var(--color-alert_error),
  alert_error_50: var(--color-alert_error_50),
  alert_info: var(--color-alert_info),
  alert_info_50: var(--color-alert_info_50),
);

.write2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  pointer-events: visible;
  position: relative;

  &--noLineHeight {
    * {
      line-height: 1 !important;
    }
  }

  &--copyPaste {
    padding: 10px;
    background-color: var(--color-background);
    border-radius: 10px;
    overflow: hidden;

    .write__copyPaste {
      position: absolute;
      width: 100%;
      height: 100%;
      inset: 0 0 0 0;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--color-primary);
      opacity: 0;
      transition: all 0.3s ease-in-out;

      &:hover {
        opacity: 1;
      }

      span {
        white-space: wrap;
        color: var(--color-primary-over);
        font-weight: bold;
        font-size: 15px;
      }
    }
  }

  &__item {
    color: inherit;
    overflow: visible;
    font-size: inherit;
    font-weight: inherit;
    font-style: inherit;
    line-height: inherit;
    text-align: inherit;
    pointer-events: none;
    font-family: 'Calibri';
  }

  &--mode {
    @each $name, $item in $write2Mode {
      &--#{$name} {
        font-size: map-get($item, font-size);
        font-weight: map-get($item, font-weight);
        font-style: map-get($item, font-style);
        color: map-get($item, color);
        line-height: map-get($item, line-height);
      }
    }
  }

  &--color {
    @each $name, $item in $writeColor {
      &--#{$name} {
        color: $item;
      }
    }
  }
}
