@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';

.usePagination {
  &__scroll {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px 2px 0;
    width: 100%;

    &__item {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  &__pagination {
    width: 100%;

    &--click {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    &--scroll {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
