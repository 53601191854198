@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';

$buttonColor: (
  error: (
    fill: (
      foregroundColor: var(--color-white),
      backgroundColor: var(--color-error),
    ),
    stroke: (
      foregroundColor: var(--color-error),
      backgroundColor: var(--color-error),
    ),
    dash: (
      foregroundColor: var(--color-error),
      backgroundColor: var(--color-error),
    ),
    link: (
      foregroundColor: var(--color-error),
      backgroundColor: var(--color-error),
    ),
    inset: (
      foregroundColor: var(--color-error),
      backgroundColor: var(--color-error),
    ),
  ),
  warn: (
    fill: (
      foregroundColor: var(--color-white),
      backgroundColor: var(--color-warn),
    ),
    stroke: (
      foregroundColor: var(--color-warn),
      backgroundColor: var(--color-warn),
    ),
    dash: (
      foregroundColor: var(--color-warn),
      backgroundColor: var(--color-warn),
    ),
    link: (
      foregroundColor: var(--color-warn),
      backgroundColor: var(--color-warn),
    ),
    inset: (
      foregroundColor: var(--color-warn),
      backgroundColor: var(--color-warn),
    ),
  ),
  success: (
    fill: (
      foregroundColor: var(--color-white),
      backgroundColor: var(--color-success),
    ),
    stroke: (
      foregroundColor: var(--color-success),
      backgroundColor: var(--color-success),
    ),
    dash: (
      foregroundColor: var(--color-success),
      backgroundColor: var(--color-success),
    ),
    link: (
      foregroundColor: var(--color-success),
      backgroundColor: var(--color-success),
    ),
    inset: (
      foregroundColor: var(--color-success),
      backgroundColor: var(--color-success),
    ),
  ),
  disabled: (
    fill: (
      foregroundColor: var(--color-black),
      backgroundColor: var(--color-disabled),
    ),
    stroke: (
      foregroundColor: var(--color-disabled),
      backgroundColor: var(--color-disabled),
    ),
    dash: (
      foregroundColor: var(--color-disabled),
      backgroundColor: var(--color-disabled),
    ),
    link: (
      foregroundColor: var(--color-disabled),
      backgroundColor: var(--color-disabled),
    ),
    inset: (
      foregroundColor: var(--color-disabled),
      backgroundColor: var(--color-disabled),
    ),
  ),
  primary: (
    fill: (
      foregroundColor: var(--color-primary-over),
      backgroundColor: var(--color-primary),
    ),
    stroke: (
      foregroundColor: var(--color-primary),
      backgroundColor: var(--color-primary),
    ),
    dash: (
      foregroundColor: var(--color-primary),
      backgroundColor: var(--color-primary),
    ),
    link: (
      foregroundColor: var(--color-primary),
      backgroundColor: var(--color-primary),
    ),
    inset: (
      foregroundColor: var(--color-primary),
      backgroundColor: var(--color-primary),
    ),
  ),
  yang: (
    fill: (
      foregroundColor: var(--color-yin),
      backgroundColor: var(--color-yang),
    ),
    stroke: (
      foregroundColor: var(--color-yang),
      backgroundColor: var(--color-yang),
    ),
    dash: (
      foregroundColor: var(--color-yang),
      backgroundColor: var(--color-yang),
    ),
    link: (
      foregroundColor: var(--color-yang),
      backgroundColor: var(--color-yang),
    ),
    inset: (
      foregroundColor: var(--color-yang),
      backgroundColor: var(--color-yang),
    ),
  ),
  yin: (
    fill: (
      foregroundColor: var(--color-yang),
      backgroundColor: var(--color-yin),
    ),
    stroke: (
      foregroundColor: var(--color-yin),
      backgroundColor: var(--color-yin),
    ),
    dash: (
      foregroundColor: var(--color-yin),
      backgroundColor: var(--color-yin),
    ),
    link: (
      foregroundColor: var(--color-yin),
      backgroundColor: var(--color-yin),
    ),
    inset: (
      foregroundColor: var(--color-yin),
      backgroundColor: var(--color-yin),
    ),
  ),
  white: (
    fill: (
      foregroundColor: var(--color-black),
      backgroundColor: var(--color-white),
    ),
    stroke: (
      foregroundColor: var(--color-white),
      backgroundColor: var(--color-white),
    ),
    dash: (
      foregroundColor: var(--color-white),
      backgroundColor: var(--color-white),
    ),
    link: (
      foregroundColor: var(--color-white),
      backgroundColor: var(--color-white),
    ),
    inset: (
      foregroundColor: var(--color-white),
      backgroundColor: var(--color-white),
    ),
  ),
  black: (
    fill: (
      foregroundColor: var(--color-white),
      backgroundColor: var(--color-black),
    ),
    stroke: (
      foregroundColor: var(--color-black),
      backgroundColor: var(--color-black),
    ),
    dash: (
      foregroundColor: var(--color-black),
      backgroundColor: var(--color-black),
    ),
    link: (
      foregroundColor: var(--color-black),
      backgroundColor: var(--color-black),
    ),
    inset: (
      foregroundColor: var(--color-black),
      backgroundColor: var(--color-black),
    ),
  ),
);

$buttonWeight: (
  light: 300,
  normal: 400,
  bold: 600,
);

$buttonSize: (
  small: (
    padding: size(6px) 0px,
    font-size: size(13px),
  ),
  medium: (
    padding: size(8px) size(0px),
    font-size: size(15px),
  ),
  large: (
    padding: size(10px) size(0px),
    font-size: size(17px),
  ),
);

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px !important;
  // overflow: hidden;

  &--size {
    @each $name, $item in $buttonSize {
      &--#{$name} {
        width: initial;

        .ant-btn {
          width: initial;
        }

        .button__item__inner {
          padding: map-get($item, padding);
        }

        .button__item__inner__text {
          font-size: map-get($item, font-size);
        }

        .button__item__inner__icon svg {
          height: map-get($item, font-size) !important;
        }
      }
    }
  }

  .ant-btn {
    display: flex;
    flex-direction: row-reverse !important;
  }

  span {
    color: inherit !important;
    font-weight: inherit !important;
  }

  .button__item * {
    font-weight: 500 !important;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: initial;
    line-height: 1;
    padding: 0px 10px !important;
    position: relative;

    &__upload {
      position: absolute;
      inset: 0 0 0 0;
      height: 100%;
      width: 100%;

      &__label {
        pointer-events: visible;
        width: 100%;
        height: 100%;
        display: block;

        &__input {
          display: none;
        }
      }
    }

    &__inner {
      display: flex;
      justify-content: center;
      align-items: center;

      &__text,
      &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__separa {
        width: 5px;
      }
    }

    &--weight {
      @each $name, $item in $buttonWeight {
        &--#{$name} span {
          line-height: 1;
          font-weight: $item;
        }
      }
    }
    &--color {
      @each $colorName, $buttonItem in $buttonColor {
        &--#{$colorName} {
          @each $modeName, $modeItem in $buttonItem {
            &--#{$modeName} {
              @if $modeName == 'fill' {
                background-color: map-get(
                  $modeItem,
                  backgroundColor
                ) !important;
                transition: all 0.3s ease-in-out !important;
                color: map-get($modeItem, foregroundColor) !important;

                &:hover {
                  opacity: 0.75 !important;
                  background-color: map-get(
                    $modeItem,
                    backgroundColor
                  ) !important;
                }
              } @else if $modeName == 'stroke' {
                border: 1px
                  solid
                  map-get($modeItem, backgroundColor) !important;
                color: map-get($modeItem, foregroundColor) !important;
                backdrop-filter: blur(20px) !important;

                &:hover {
                  opacity: 0.75;
                  border: 1px
                    solid
                    map-get($modeItem, backgroundColor) !important;
                }
              } @else if $modeName == 'dash' {
                border: 1px
                  dashed
                  map-get($modeItem, backgroundColor) !important;
                color: map-get($modeItem, foregroundColor) !important;

                &:hover {
                  border: 1px
                    dashed
                    map-get($modeItem, backgroundColor) !important;
                }
              } @else if $modeName == 'link' {
                border: none !important;

                &:hover {
                  border: none !important;
                }

                color: map-get($modeItem, foregroundColor) !important;
              } @else if $modeName == 'inset' {
                border: 1px
                  solid
                  map-get($modeItem, backgroundColor) !important;
                color: map-get($modeItem, foregroundColor) !important;
                backdrop-filter: blur(20px) !important;
                background-color: var(--color-yin) !important;

                &:hover {
                  opacity: 0.75;
                  border: 1px
                    solid
                    map-get($modeItem, backgroundColor) !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
