@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/mixin.scss';
@import '../../sass/utils/placeholder.scss';

.navdesktop {
  height: 100%;
  max-height: 100%;
  position: relative;
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  backdrop-filter: blur(60px);
  z-index: 100;
  @extend %layout;

  &--drop {
    grid-template-areas: 'parent';
    grid-template-columns: 1fr;

    .navdesktop__parent {
      width: size(260px);
      height: 100%;
    }

    .navdesktop__parent__menu,
    .navdesktop__parent__more {
      padding: 0px;
      justify-content: flex-start;
    }
  }

  &--panel {
    grid-template-areas: 'parent child';
    grid-template-columns: 1fr 1fr;

    .navdesktop__parent,
    .navdesktop__child {
      width: 200px;
    }

    .navdesktop__parent__menu,
    .navdesktop__parent__more {
      justify-content: flex-start;
    }
  }

  &__parent {
    overflow: hidden;
    grid-area: parent;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    z-index: 2;

    &__logo {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 0px;
      margin: size(20px) 0;
      height: min-content;
      min-height: 100px;

      * {
        background-color: transparent !important;
      }

      svg,
      img {
        object-fit: contain;
        margin-bottom: 20px;
        max-width: 80%;
        height: size(120px);

        &:last-of-type {
          margin-bottom: 0px;
        }
      }
    }

    &__menu {
      height: 100%;
      width: 100%;
      overflow-y: auto;
      flex-direction: column;
      padding-top: 10px;
      position: relative;
      @include scrollbar();
      position: relative;
    }

    &__more {
      padding-top: 10px;
      position: sticky;
      bottom: 0px;
      width: 100%;
      z-index: 4;
    }
  }

  &__child {
    grid-area: child;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    &__contain {
      overflow: hidden;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      &__toggle {
        height: 40px;
        background-color: var(--color-primary);
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }

    &__open {
      cursor: pointer;
      position: absolute;
      right: 0px;
      transform: translateX(100%);
      height: 70px;
      background-color: var(--color-primary);
      width: 14px;
      border-radius: 0 5px 5px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      z-index: 2;
      pointer-events: 'none';

      &__line {
        background-color: var(--color-yin);
        height: 80%;
        width: 2px;
      }

      * {
        pointer-events: none;
      }
    }
  }
}

.navmobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 15px;
  @media screen and (max-width: map-get($breakpoint, 'navigation')) {
    background-color: var(--color-layout);
  }

  @media screen and (max-width: map-get($device, 'desktop')) {
    background-color: var(--color-background);
  }

  &__part {
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;

    &:nth-child(1) {
      justify-content: flex-start;

      .icon {
        font-size: size(20px);
      }
    }

    &:nth-child(2) {
      justify-content: center;
    }

    &:nth-child(3) {
      justify-content: flex-end;
    }

    &__menu {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      &__text {
        margin-bottom: 25px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        &__title {
          margin-bottom: 3px;
        }
      }
    }

    &__title {
      * {
        white-space: nowrap;
      }
    }
  }
}

.navinline {
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0px;
  right: 0px;
  z-index: 99999999;
  width: 100%;
  pointer-events: none;
  padding: 10px 0;

  * {
    pointer-events: visible;
  }

  &__contain {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 7px;
      cursor: pointer;
      padding: 8px 12px;
      overflow: hidden;
      color: var(--color-yang);
      backdrop-filter: blur(100px);
      @extend %layout;

      &.selected * {
        color: var(--color-primary);
      }

      * {
        pointer-events: none;
        transition: all 0.3 ease-in-out;
      }

      &:hover * {
        color: var(--color-primary);
      }

      svg {
        font-size: size(17px);
      }

      &__text {
        margin-left: 5px;
        font-size: size(13px);
      }
    }
  }

  &__extra {
    width: 100%;
  }
}
