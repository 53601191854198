@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';

$iconSize: (
  xxxsmall: size(12px),
  xxsmall: size(14px),
  xsmall: size(16px),
  small: size(18px),
  medium: size(20px),
  xmedium: size(22px),
  large: size(25px),
  xlarge: size(30px),
  xxlarge: size(35px),
  list: size(23px),
  mega: size(60px),
  giga: size(90px),
  tera: size(120px),
);

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-text);

  .icon__custom {
    display: flex;
    justify-content: center;
    align-items: center;

    * {
      fill: var(--color-yang);
    }
  }

  &--color {
    @each $name, $item in $iconColor {
      &--#{$name} {
        color: $item;

        .icon__custom *:not(.color-preserve) {
          fill: $item !important;
        }
      }
    }
  }

  &--size {
    @each $name, $item in $iconSize {
      &--#{$name} {
        .icon__lib,
        svg {
          height: $item;
          width: auto !important;
        }
      }
    }
  }

  &--free {
    height: 100%;
    width: 100%;
    .icon__custom,
    .icon__custom svg,
    .icon__lib {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
