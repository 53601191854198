@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';

.code {
  width: 100%;
  overflow: hidden;
  border: map-get($border, line);
  border-radius: 3px;
  height: 100%;
  resize: vertical;
  .monaco-editor,
  .monaco-editor-background,
  .overflow-guard .margin {
    background-color: transparent !important;
  }

  .current-line,
  .scroll-decoration {
    display: none !important;
  }

  .monaco-editor {
    position: absolute !important;
  }
}
