@use 'sass:color';
@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/mixin.scss';

$badgeSize: (
  xsmall: (
    padding: size(3px) size(4px),
    font-size: size(10px),
  ),
  small: (
    padding: size(4px) size(6px),
    font-size: size(11px),
  ),
  medium: (
    padding: size(5px) size(7px),
    font-size: size(12px),
  ),
  large: (
    padding: size(6.5px) size(9px),
    font-size: size(15px),
  ),
  xlarge: (
    padding: size(8px) size(10px),
    font-size: size(16px),
  ),
);

$tagSize: (
  small: 14px,
  medium: 16px,
  large: 18px,
  xlarge: 20px,
);

.witness {
  aspect-ratio: 1;
  border-radius: 100%;

  &--size {
    @each $name, $item in $tagSize {
      &--#{$name} {
        height: $item;
      }
    }
  }
}

.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: min-content !important;

  &--radius {
    border-radius: 3px;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;

    * {
      color: inherit !important;
    }
  }

  &__text {
    font-weight: 500;
    line-height: 1;
    white-space: nowrap;
    color: inherit;
  }

  &--size {
    @each $name, $item in $badgeSize {
      &--#{$name} {
        padding: map-get($item, padding);

        .badge__text {
          font-size: map-get($item, font-size);
        }

        .badge__icon * {
          height: calc(map-get($item, font-size) + 4px) !important;
        }
      }
    }
  }

  .badge__text {
    font-weight: 600 !important;
  }
}
