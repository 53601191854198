@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';

.paginationclick {
  padding: 15px 18px 0px 15px;

  .ant-pagination,
  .ant-pagination-options {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  * {
    font-size: size(14px) !important;
  }

  .ant-pagination-item,
  .ant-select-single,
  .ant-pagination-options-quick-jumper input {
    min-width: size(32px);
    height: size(32px) !important;
  }

  .ant-pagination-item a,
  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100% !important;
  }
}

.paginationscroll {
}
