@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/mixin.scss';
@import '../../sass/utils/placeholder.scss';

$formItemLabelColor: (
  label: var(--color-label),
  yin: var(--color-yin),
  yang: var(--color-yang),
  white: var(--color-white),
  black: var(--color-black),
);

.form {
  display: grid;
  grid-template: 1fr / 1fr;
  height: 100%;
  overflow: hidden;
  width: 100%;

  &__contain {
    width: 100%;
    overflow: hidden;
  }

  // &:has(.formstructure) {
  //   height: 100%;
  //   min-height: 100%;
  // }
}

.formitem--labelLimitation.formitem--horizontal {
  .ant-form-item-label {
    width: 35%;

    .ant-form-item-no-colon {
      overflow: hidden !important;
      display: inline-block !important;
      white-space: nowrap !important;
      text-overflow: ellipsis !important;
    }
  }
}

.formitem {
  margin-bottom: 0px;

  &--color {
    @each $name, $item in $formItemLabelColor {
      &--#{$name} {
        .ant-form-item-label label {
          color: #{$item} !important;
        }
      }
    }
  }

  &:has(.formItemTargetWidth--full) {
    width: 100%;
  }

  &:has(.formItemTargetHeight--full) {
    height: 100% !important;

    .ant-form-item-row,
    .ant-form-item-control,
    [class^='ant-form-item-control'] {
      height: 100% !important;
    }
  }

  label {
    display: inline-block !important;
    font-size: size(12px) !important;

    &::first-letter {
      text-transform: uppercase !important;
    }
  }

  .ant-form-item-label {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }

  .ant-col {
    margin-bottom: 0px !important;
  }

  .ant-form-item-explain-error {
    margin-top: 3px !important;
    font-size: size(11px) !important;
  }

  .ant-form-item-label label {
    font-size: map-get(map-get($writeMode, label), font-size) !important;
    font-weight: map-get(map-get($writeMode, label), font-weight) !important;
    font-style: map-get(map-get($writeMode, label), font-style) !important;
  }

  .ant-form-item-tooltip {
    color: var(--color-primary) !important;
  }

  .ant-form-item-label {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    margin-right: 10px !important;
  }

  &--horizontal {
    .ant-form-item-control-input {
      width: 100% !important;
    }

    .ant-form-item-row {
      // width: 100% !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ant-form-item-label {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    label {
      line-height: initial;
      height: initial !important;
    }

    .ant-col {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }

    .ant-form-item-control-input {
      min-height: initial !important;
    }
  }

  &--vertical {
    // width: 100% !important;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: initial !important;

    .ant-form-item-row,
    .ant-form-item-control,
    .ant-form-item-control-input,
    .ant-form-item-control-input-content {
      width: 100% !important;
    }

    .ant-form-item-explain {
      margin-top: 0px !important;
    }

    .ant-form-item-row {
      // width: 100% !important;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .ant-form-item-control,
    .ant-form-item-control * {
      flex: none;
    }

    label {
      margin-bottom: 3px;
      height: initial !important;
    }

    .ant-form-item-label {
      height: size(22px) !important;
    }
  }
}

.ant-tooltip:not(.ant-tooltip-hidden) {
  display: flex !important;

  * {
    color: inherit !important;
  }

  .ant-tooltip-inner {
    padding: 10px !important;
  }

  .ant-tooltip-inner,
  .ant-tooltip-arrow::after,
  .ant-tooltip-arrow::before {
    background-color: var(--color-yin) !important;
  }
}

$entry: (
  initial: 'initial',
  small: 20%,
  medium: 50%,
  large: 60%,
  xlarge: 90%,
  full: 100%,
);

.formstructure {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: auto;
  width: 100%;

  &__contain {
    display: flex;
    @include scrollbar();
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    padding: 0 10px;

    &--layout {
      &--row {
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: row;
      }

      &--column {
        justify-content: flex-start;
        flex-direction: column;
      }
    }

    &--width {
      @each $name, $item in $entry {
        &--#{$name} {
          width: #{$item};
        }
      }
    }

    &__unit {
      margin: 0 20px 20px 0;

      &--fill {
        width: 100%;
      }
    }
  }
}

.ant-form-item .ant-form-item-control-input {
  min-height: initial !important;
}

.ant-form-item-no-colon {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
