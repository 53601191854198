@import '@gimlite/watermelon/sass/utils/variable.scss';
@import '@gimlite/watermelon/sass/utils/function.scss';
@import '@gimlite/watermelon/sass/utils/mixin.scss';

$radiusDrawerMobile: 20px;

$drawerSize: (
  horizontal: (
    xsmall: clamp(200px, 20dvw, 350px),
    small: clamp(200px, 30dvw, 550px),
    medium: clamp(200px, 40dvw, 700px),
    xmedium: clamp(200px, 50dvw, 900px),
    large: clamp(200px, 60dvw, 1100px),
    xlarge: clamp(200px, 70dvw, 1300px),
    xxlarge: clamp(200px, 80dvw, 1500px),
  ),
  vertical: (
    xsmall: 20dvh,
    small: 30dvh,
    medium: 40dvh,
    xmedium: 50dvh,
    large: 60dvh,
    xlarge: 70dvh,
    xxlarge: 80dvh,
  ),
);

.drawer {
  .widget,
  .widgetgroup {
    padding: 0px !important;
    margin: 0px !important;
    max-width: 100% !important;
    box-shadow: none !important;

    .wid-header {
      @media screen and (max-width: map-get($device, desktop)) {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }

  &--horizontal {
    @each $name, $item in map-get($drawerSize, horizontal) {
      &--#{$name} {
        .ant-drawer-content-wrapper,
        .ant-drawer-content {
          height: 100% !important;
          width: $item !important;
          max-width: $item !important;
        }
      }
    }
  }

  &--vertical {
    @each $name, $item in map-get($drawerSize, vertical) {
      &--#{$name} {
        .ant-drawer-content-wrapper,
        .ant-drawer-content {
          height: $item !important;
          width: 100% !important;
          max-height: $item !important;
        }
      }
    }
  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    pointer-events: visible !important;
    display: none;

    * {
      pointer-events: none;
    }
  }
}

.ant-drawer-bottom {
  @media screen and (max-width: map-get($device, desktop)) {
    .ant-drawer-content-wrapper,
    .ant-drawer-content {
      border-radius: $radiusDrawerMobile $radiusDrawerMobile 0 0 !important;
    }
  }
}

.ant-drawer-top {
  @media screen and (max-width: map-get($device, desktop)) {
    .ant-drawer-content-wrapper,
    .ant-drawer-content {
      border-radius: 0 0 $radiusDrawerMobile $radiusDrawerMobile !important;
    }
  }
}

.ant-drawer-header {
  position: absolute;
  width: 100%;
  border: none !important;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 2;

  * {
    pointer-events: none;
  }

  .ant-drawer-header-title,
  .ant-drawer-header-title * {
    display: none !important;
  }
}

.ant-drawer-body {
  @include scrollbar();
  transform: translateY(-3px);
  z-index: 1;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  padding: size(20px) !important;

  @media screen and (min-width: map-get($device, desktop)) {
    display: grid !important;
    align-content: flex-start !important;
  }
}

.drawertrigger {
  background-color: transparent;
  cursor: pointer;

  * {
    pointer-events: none;
  }
}
