@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';

$popoverWidth: (
  initial: initial,
  xsmall: 100px,
  small: 200px,
  xmedium: 300px,
  medium: 400px,
  large: 500px,
  xlarge: 600px,
);

.popover {
  &--minWidth {
    @each $name, $item in $popoverWidth {
      &--#{$name} {
        .ant-popover-content {
          min-width: #{$item} !important;
        }
      }
    }
  }

  &--maxWidth {
    @each $name, $item in $popoverWidth {
      &--#{$name} {
        .ant-popover-content {
          max-width: #{$item} !important;
        }
      }
    }
  }

  &--nopadding {
    .ant-popover-inner {
      padding: 0px !important;
    }
  }

  &__button {
    display: flex;
    cursor: pointer !important;
    background-color: transparent;
  }

  .ant-popover-inner {
    .widget,
    .widgetgroup {
      // margin: 0px !important;
      padding: 0px !important;
      box-shadow: none !important;
    }
  }
}

.ant-popover-inner {
  .widget,
  .widgetgroup {
    // margin: 0px !important;
    padding: 0px !important;
    box-shadow: none !important;
  }
}

$popoverConfirmWidth: (
  initial: initial,
  full: 100%,
);

.popoverconfirm {
  &--width {
    @each $name, $item in $popoverConfirmWidth {
      &--#{$name} {
        width: #{$item} !important;
      }
    }
  }
}

.ant-popconfirm {
  svg {
    color: var(--color-error) !important;
  }

  .ant-popconfirm-title,
  .ant-popconfirm-description {
    font-size: size(15px) !important;
    max-width: 300px;
  }

  .ant-popconfirm-buttons {
    .ant-btn {
      height: size(24px);
      line-height: 1 !important;

      span {
        font-size: size(14px) !important;
      }
    }
  }
}
