@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';

.tab {
  width: 100%;
  height: 100% !important;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;

  .ant-tabs-content-holder,
  .ant-tabs-content,
  .ant-tabs-tabpane {
    height: 100% !important;
    min-height: 100% !important;
  }

  .ant-tabs-nav {
    margin: 0px !important;

    &::before {
      border-color: var(--color-border) !important;
    }
  }

  .ant-tabs-content {
    padding-top: $space !important;
  }
}
