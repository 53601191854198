@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../search/search.component.scss';

.autocomplete {
  * {
    font-size: map-get($writeMode, input, font-size) !important;
    font-weight: map-get($writeMode, input, font-weight) !important;
    font-style: map-get($writeMode, input, font-style) !important;
  }
  .autocomplete-node {
    .ant-select,
    .ant-select-selector,
    .ant-select-selection-search {
      display: flex !important;
      justify-content: space-between !important;
      align-items: center !important;
    }
  }
  .ant-select {
    width: 100% !important;
  }

  // .ant-select-single .ant-select-selector .ant-select-selection-search {
  //   position: static !important;
  // }

  .ant-select-selection-search {
    // inset-inline-start: map-get($entry, padding, x) !important;
    inset-inline-end: map-get($entry, padding, x) !important;
  }

  .ant-select-single {
    height: initial !important;
  }

  .ant-select-selector {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .ant-select-selection-placeholder {
      line-height: initial !important;
    }
  }

  &--width {
    @each $name, $item in map-get($entry, width) {
      &--#{$name} {
        width: #{$item} !important;
      }
    }
  }

  &--minWidth {
    @each $name, $item in map-get($entry, width) {
      &--#{$name} {
        min-width: #{$item};
      }
    }
  }

  &--height {
    @each $name, $item in map-get($entry, height) {
      &--#{$name} {
        .ant-select-selector {
          height: #{$item} !important;
        }

        input {
          margin-top: 0px !important;
        }
      }
    }
  }

  .ant-select-selector {
    padding: map-get($entry, padding, y) map-get($entry, padding, x) !important;
  }
}
