@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/mixin.scss';
@import '../../sass/utils/placeholder.scss';

.wizard {
  width: 95vw;
  max-width: 1600px;
  height: 95vh;
  max-height: 1000px;
  display: grid;
  grid-template-columns: 1fr;
  //! grid-template-rows en JS
  padding: 20px;
  position: relative;
  background-color: var(--color-layout);
  overflow: auto;

  &__close {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
  }

  &__title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  &__steps {
    margin: 20px 0 15px 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;

    &__retry {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: scale(1.1);
      }

      * {
        pointer-events: none;
      }

      svg {
        font-size: size(30px);
        color: var(--color-text);
      }
    }
  }

  &__form {
    overflow: auto;
    padding: 5px;
    @include scrollbar();

    &__contain {
      display: grid;
      grid-template: 1fr / 1fr;
      overflow: auto;
      height: 100%;
      @include scrollbar();

      &__item {
        overflow: auto;

        &__finish {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &__spin {
        overflow: hidden;

        &__contain {
          display: grid;
          justify-content: center;
          align-items: center;
          grid-template: 1fr / 1fr;
          height: 100%;
        }
      }
    }
  }

  &__nav {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__part {
      &__button {
        width: 100px;
      }
    }
  }

  &__error {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 20px;

    span {
      color: var(--color-error);
      font-size: size(14px);
    }
  }
}

.wizard-card-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  @include scrollbar();

  &__text {
    margin-bottom: size(20px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &__title {
    }

    &__subtitle {
      margin-top: 5px;
    }
  }

  &__slider {
    width: 100%;
    height: 100%;
    overflow-x: visible;
  }

  &__wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    [class^='wizard-card-'] {
      margin: 10px;
    }
  }
}

@mixin wizard-card() {
  padding: size(20px);
  @extend %card;
}

.wizard-card-link {
  width: size(250px);
  aspect-ratio: 1 / 1.2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @include wizard-card();
  pointer-events: visible;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: var(--color-selected);
    transform: scale(1.05);
  }

  * {
    pointer-events: none;
  }

  &__image {
    aspect-ratio: 1 / 1 !important;
    width: 100% !important;

    svg {
      width: 130px !important;
      height: 100% !important;
    }
  }

  &__text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
  }
}

.wizard-card-resume,
.wizard-card-info {
  height: 100%;
  width: size(420px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @include wizard-card();

  &__title {
    margin-bottom: size(25px);
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    &__line {
      margin-bottom: size(15px);
      display: grid;
      grid-template-columns: 1fr;
      grid-template-columns: repeat(2, 1fr);
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;

      &__label,
      &__value {
        // overflow: hidden;
      }
    }
  }
}
