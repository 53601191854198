@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/mixin.scss';
@import '../../sass/utils/placeholder.scss';

.table {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius);

  &--cellPadding {
    .ant-table-cell {
      padding: 0px !important;
      padding-left: 10px !important;

      &:last-of-type {
        padding-right: 18px !important;
      }
    }
  }

  &--noCellPadding {
    .ant-table-cell {
      padding: 0px !important;
    }
  }

  &--border {
    border: 1px solid var(--color-border) !important;

    .ant-table-cell {
      border-right: 1px solid var(--color-border) !important;
      &:last-of-type {
        border-right: 1px solid transparent !important;
      }
    }
  }

  &--noBorder {
    //! Gérer par défaut
  }

  &--headColor {
    .ant-table-thead .ant-table-cell {
      color: var(--color-label) !important;
      background-color: var(--color-background) !important;
    }
  }

  &--noHeadColor {
    .ant-table-thead .ant-table-cell {
      color: var(--color-label) !important;
      background-color: transparent !important;
    }
  }

  &__skeleton {
    margin-top: $space;
  }

  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;

    &__item {
      margin: 0 5px;

      &:first-of-type {
        margin-left: 0px;
      }

      &:last-of-type {
        margin-right: 0px;
      }
    }
  }

  &__contain {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: auto;
    @include scrollbar();
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.alternateColor {
      .ant-table-row {
        &:nth-of-type(odd) {
          background-color: var(--color-background) !important;
        }
      }
    }

    &.table-read tbody {
      .ant-table-row {
        * {
          cursor: pointer !important;
        }

        transition: all 0.3s ease-in-out;
      }

      .ant-table-cell-row-hover,
      .ant-table-row:has(.tableSelected) {
        background-color: var(--color-selected) !important;
      }
    }

    .ant-table-tbody,
    .ant-table-thead {
    }

    .ant-table-cell {
      border-bottom: map-get($border, line) !important;
    }

    .ant-table-thead {
      inset: 0 0 0 0;
      z-index: 20;
      width: 100%;
      overflow: visible;
      position: sticky;
      top: 0px;
      backdrop-filter: blur(30px);
      background-color: var(--color-layout) !important;

      .ant-table-cell {
        padding: 10px !important;

        * {
          color: inherit !important;
        }

        .ant-table-column-sorter-up,
        .ant-table-column-sorter-down {
          color: var(--color-disabled) !important;

          &.active {
            color: var(--color-primary) !important;
          }
        }

        &::before {
          opacity: 0 !important;
        }

        &::first-letter {
          text-transform: capitalize !important;
        }
      }

      .ant-table-cell,
      .ant-table-column-title {
        font-size: map-get($writeMode, 'cell-title', 'font-size');
        font-weight: map-get($writeMode, 'cell-title', 'font-weight');
        font-style: map-get($writeMode, 'cell-title', 'font-style');
      }
    }

    .ant-table-tbody {
      // * {
      //   cursor: default;
      // }

      .ant-table-cell {
        font-size: map-get($writeMode, 'cell-value', 'font-size');
        font-weight: map-get($writeMode, 'cell-value', 'font-weight');
        font-style: map-get($writeMode, 'cell-value', 'font-style');
        height: size(40px);
        cursor: default;
        img {
          padding: 4px 0;
        }

        span {
          font-weight: inherit;
        }
      }

      .ant-table-row {
        &:first-of-type .ant-table-cell {
          border-top: 0px solid transparent !important;
        }
      }

      .ant-table-cell-row-hover {
        cursor: pointer;
      }

      .ant-table-expanded-row .ant-table-cell {
        padding: 10px 18px !important;
      }
    }
  }

  &__page {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
  }
}
