@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';

.menu {
  padding-left: 5px;
  width: 100% !important;
  background-color: transparent !important;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  * {
    box-sizing: border-box !important;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100% !important;
    overflow: visible;

    &__extra {
      transform: translateX(-15px);
    }
  }

  &--select--deep {
    .ant-menu-item-selected::after {
      content: '';
      display: inline-block;
      height: 10px !important;
      width: 10px !important;
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
      background-color: var(--color-primary);
      z-index: 5;
      border-radius: 100%;
    }

    .ant-menu-item-selected * {
      overflow: visible;
      color: var(--color-text) !important;
    }
  }

  &--mode--flat,
  &--mode--recursive {
    *:not(.menu__item, .menu__item *) {
      background-color: transparent !important;
    }

    .ant-menu-item-group-list,
    .ant-menu-submenu-title,
    .ant-menu-item {
      padding: 0px 15px 0px 10px !important;
    }

    .ant-menu .ant-menu-item {
      padding: 0px 11px 0px 10px !important;
    }

    .ant-menu-item {
      width: 100% !important;
      max-width: 100% !important;
    }
    .ant-menu-item-group-list > .ant-menu-item {
      margin: 0px !important;
    }

    .ant-menu-sub .ant-menu-item {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }

    .ant-menu-item-group-list {
      padding: 0px !important;
    }

    &.ant-menu {
      border-inline-end: 1px solid transparent !important;
      padding: 0px 0px !important;
    }

    .ant-menu-item-group-title {
      padding-left: 10px !important;
      text-transform: uppercase;
      font-size: size(11px);
      font-weight: 600;

      @media screen and (max-width: map-get($device, desktop)) {
        padding-left: 0px !important;
      }
    }

    .ant-menu-submenu {
      width: 100% !important;
    }

    .ant-menu-title-content {
      font-size: size(14px);
      font-weight: bold;
      line-height: 1.2 !important;
      margin-inline-start: 8px !important;
    }

    .ant-menu-submenu-title {
      margin: 0px !important;
      position: relative !important;
      width: 100% !important;
      transform: translateX(0px) !important;

      .ant-menu-submenu-arrow {
        right: 0px;
        @media screen and (min-width: map-get($device, desktop)) {
          inset-inline-end: -5px !important;
        }

        &::after {
        }
      }
    }

    .ant-menu-submenu:has(.ant-menu-item-selected) .ant-menu-submenu-title {
      color: var(--color-primary) !important;
    }
  }

  &--mode--flat {
    margin-bottom: 20px;
    .ant-menu-item-selected {
      background-color: transparent;

      &::after {
        content: '';
        z-index: 2;
        position: absolute;
        right: -1px;
        height: 90%;
        width: 3px;
      }
    }

    .ant-menu-inline {
      background-color: var(--color-primary) !important;
    }

    .ant-menu-item-group-list {
      padding: 0 10px !important;
    }

    .ant-menu-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 0px !important;
      padding-inline: 0px !important;
      width: 100% !important;
      border-radius: 0px;
      overflow: visible;
      height: initial !important;
      padding: size(8px) 0px !important;

      &:hover {
        background-color: transparent !important;
      }
    }
  }

  &--mode--recursive {
    margin-bottom: size(30px);

    .ant-menu-sub {
      padding: 5px 0 5px 0 !important;
      background-color: var(--color-background) !important;

      .ant-menu-item {
        color: var(--color-text) !important;
      }

      .ant-menu-item.ant-menu-item-selected {
        background-color: var(--color-selected) !important;

        color: var(--color-primary) !important;
      }
    }

    .ant-menu-submenu-arrow {
      transform: translateX(-15px) !important;

      @media screen and (max-width: map-get($device, desktop)) {
        transform: translateX(0px) !important;
      }
    }

    .ant-menu-submenu-title {
      border-radius: 0px;
    }
  }
}

.ant-menu-title-content {
  overflow: visible !important;
}

.ant-menu-submenu-selected {
  .ant-menu-submenu-title,
  .ant-menu-item-selected {
    .menu__item__label {
      color: var(--color-primary) !important;
    }

    .menu__item__icon {
      color: var(--color-primary) !important;

      svg,
      svg * {
        fill: var(--color-primary) !important;
      }
    }
  }
}

.ant-menu-submenu:not(.ant-menu-submenu-selected) {
  .ant-menu-submenu-title,
  .ant-menu-item-selected {
    .menu__item__label {
      color: var(--color-label) !important;
    }

    .menu__item__icon {
      color: var(--color-label) !important;
      fill: var(--color-label) !important;
    }
  }
}

.ant-menu-item.ant-menu-item-selected {
  .menu__item__label {
    color: var(--color-primary) !important;
  }

  .menu__item__icon {
    color: var(--color-primary) !important;
    svg,
    svg * {
      fill: var(--color-primary) !important;
    }
  }
}

.ant-menu-item:not(.ant-menu-item-selected) {
  .menu__item__label {
    color: var(--color-label) !important;
  }

  .menu__item__icon {
    color: var(--color-label) !important;
    fill: var(--color-label) !important;
  }
}
.ant-menu-sub {
  width: 100% !important;
}

.menu,
.ant-menu-item-group,
.ant-menu-item-group-list,
.ant-menu-submenu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100% !important;
}

.ant-menu.ant-menu-sub {
  padding: 4px !important;

  .ant-menu-item {
    margin-inline: 0px !important;
  }
}

.ant-menu-title-content {
  width: 100% !important;
}

.ant-menu-inline {
  .ant-menu-item,
  .ant-menu-submenu-title {
    height: size(35px) !important;
  }
}
